.content {
  padding-inline: 20px;
}

.padding-inline {
  padding-inline: 10% !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

#main-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  height: 80px;
  left: 0;
  box-sizing: border-box;
  padding-inline: 20px;
  background: #282c34;
  top: 0;
  padding-top: 20px;
  z-index: 800;
}

.launch-container .section-item-menu ul li {
  margin-block: 30px;
  margin-left: 30px;
}

#main-nav li {
  border-bottom: 3px solid transparent;
  padding-bottom: 10px;
}
.activenav {
  border-bottom: 3px solid #3f88f8 !important;
  padding-bottom: 10px;
}
.launch-container {
  padding-inline: 1%;
  position: relative;
  padding-top: 65px;
}
.text-center {
  text-align: center !important;
}
.flex-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.aling-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.launch-container {
  height: 100vh;
}

.social-network {
  display: flex;
  width: 220px;
  font-size: 30px;
  color: white;
  justify-content: space-between;
}
.social-network a {
  color: white;
}
.social-network-footer {
  display: flex;
  width: 220px;
  font-size: 30px;
  color: white;
  justify-content: space-between;
}

.social-network-footer a {
  color: white;
}
.launch-container p {
  width: 80%;
  text-align: center;
  font-size: 30px;
}
.logoLaunch {
  width: 350px;
}

.launch-btns {
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.launch-btns div {
  width: 25%;
  margin-inline: 40px;
}

.launch-btns button {
  height: 70px;
}

.line-container {
  position: absolute;
  width: 100%;
  height: 100vh;
}

.line-horizontal,
.line-vertical {
  background-color: #00f0ff;
  box-shadow: 0 0 10px #00f0ff, 0 0 20px #00f0ff, 0 0 30px #00f0ff,
    0 0 40px #00f0ff, 0 0 50px #00f0ff;
  animation: neon-glow 2s infinite alternate;
}

.line-horizontal {
  position: absolute;
  top: 40%;
  right: 0px;
  width: 80px;
  height: 2px;
}

.line-horizontal.secondary {
  position: absolute;
  top: 40%;
  right: 100px;
  width: 20px;
  height: 2px;
}

.line-vertical {
  position: absolute;
  bottom: 0%;
  left: 50%;
  width: 2px;
  height: 40px;
  transform: translateX(-50%);
}

nav .logoLaunch {
  width: 170px;
  padding-inline: 20px;
}
nav {
  padding-bottom: 20px;
  border-bottom: 1px solid #968d8d38;
}
nav .content-nav {
  display: flex;
  align-items: center;
}
nav .content-nav ul {
  display: flex;
  list-style: none;
  gap: 40px;
}
nav .content-nav ul li a {
  color: white;
  font-weight: bold;
}

#hero {
  width: 70%;
  padding-inline: 50px;
  font-size: 60px;
}

#usability {
  display: flex;
  margin-top: 2%;

  justify-content: center;
}
#usability div {
  width: 33.33%;
  display: flex;
  justify-content: center;
  text-align: center;
}

#usability .item-usability {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 20%;
}

.item-usability.left {
  align-items: flex-end;
}
.item-usability.right {
  align-items: flex-start;
}

#image-phone img {
  object-fit: cover;
  width: 600px;
  height: 600px;
}
#usability .item-usability .item {
  background-color: #404040;
  padding-inline: 48px;
  padding-block: 30px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 17px;
  width: 70%;
}

#about {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

#about h1 {
  margin-bottom: 50px;
}

.card-home {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #404040;
  border-radius: 50px;
}
.card-home p {
  width: 100%;
  text-align: left;
  font-size: 22px;
  line-height: 30px;
}

#roadmap ul {
  font-size: 20px;
  color: white;
  font-weight: bold;
  line-height: 50px;
}

#roadmap ul li::marker {
  font-size: 40px;
}

#roadmap ul.active li::marker {
  color: #2e75b6;
}
#roadmap img {
  width: 100%;
}

.laptop-content-img img {
  width: 100%;
}

#content-access div {
  justify-content: center;
  display: flex;
  gap: 20px;
}

#content-access div img {
  width: 10%;
}

.faq-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.faq-item {
  background-color: #404040;
  color: #fff;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 15px;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  width: 100%;
}

.faq-answer-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.faq-answer-container.open {
  max-height: 300px;
  padding: 10px 20px;
}

.faq-answer {
  font-size: 14px;
  line-height: 1.5;
}

.arrow {
  font-size: 12px;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

#start-you-crypto .card-home .flex-row div.item-start-your {
  width: 50%;
}

#start-you-crypto .card-home .flex-row div.item-start-your img {
  width: 100%;
  position: relative;
  margin-bottom: -20px;
}

#impact img {
  object-fit: contain;
}
.logofooter {
  width: 50%;
}

#footer-btns {
  width: 70%;
  display: flex;

  margin: 0 auto;
}

#footer-btns div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

#footer-btns div a {
  background-color: #3f88f8;
  width: 45%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
}
#footer-btns div div a {
  color: white;
}

#content-footer-logo {
  width: 50%;
}

#content-footer-logo img {
  width: 40%;
}

.icon-bars-menu-launch {
  display: none;
  position: absolute;
  z-index: 999;
  top: 20px;
  right: 40px;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

@keyframes neon-glow {
  0% {
    box-shadow: 0 0 5px #00f0ff, 0 0 10px #00f0ff, 0 0 15px #00f0ff,
      0 0 20px #00f0ff;
  }
  50% {
    box-shadow: 0 0 10px #00f0ff, 0 0 20px #00f0ff, 0 0 30px #00f0ff,
      0 0 40px #00f0ff;
  }
  100% {
    box-shadow: 0 0 5px #00f0ff, 0 0 10px #00f0ff, 0 0 15px #00f0ff,
      0 0 20px #00f0ff;
  }
}

@media (max-width: 1140px) {
  .launch-container,
  .padding-inline {
    padding-inline: 2% !important;
  }
}

@media (max-width: 1277px) {
  .launch-btns div {
    width: 35%;
  }
}

@media (max-width: 915px) {
  .launch-btns div {
    width: 45%;
  }
}
@media (max-width: 780px) {
  .flex-row {
    flex-direction: column;
  }

  .launch-container {
    overflow: scroll;
    overflow-x: hidden;
  }

  #usability {
    margin-top: 60px;
    flex-direction: column;
    align-items: center;
  }

  #image-phone {
    order: -1;
    margin-bottom: 30px;
  }

  .item-usability.left,
  .item-usability.right {
    align-items: center;
    width: 60% !important;
  }
  #usability .item-usability .item {
    margin-bottom: 40px;
    width: 60%;
  }
  #start-you-crypto .card-home .flex-row div.item-start-your {
    width: 100% !important;
  }

  #content-footer-logo,
  #footer-btns {
    width: 100%;
  }

  #roadmap .card-home {
    margin-bottom: 40px !important;
  }

  #hero {
    width: 90%;
    padding-inline: 20px;
    font-size: 60px;
  }
}
@media (max-width: 600px) {
  .icon-bars-menu-launch {
    display: block;
  }
  nav .logoLaunch {
    width: 150px;
  }
  nav ul,
  nav .social-network {
    display: none !important;
  }

  .logoLaunch {
    width: 200px;
  }

  .line-horizontal {
    width: 65px;
  }

  .line-horizontal.secondary {
    right: 80px;
  }

  .launch-btns div {
    margin-inline: 15px;
  }
}

@media (max-width: 400px) {
  .logoLaunch {
    width: 200px;
  }

  .line-horizontal {
    width: 55px;
  }

  .line-horizontal.secondary {
    right: 60px;
  }
}
